import * as i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import deTranslation from '../locales/de/translation.json'
import enTranslation from '../locales/en/translation.json'
import esESTranslation from '../locales/es-ES/translation.json'
import esMXTranslation from '../locales/es-MX/translation.json'
import esUSTranslation from '../locales/es-US/translation.json'
import frCATranslation from '../locales/fr-CA/translation.json'
import frFRTranslation from '../locales/fr-FR/translation.json'
import jpTranslation from '../locales/jp/translation.json'
import ptTranslation from '../locales/pt/translation.json'

import type { Resource, InitOptions } from 'i18next'

export interface UILabels {
  mainNavigation: string
  learnMore: string
  call: string
  text: string
  downloadApp: string
  searchLabel: string
  closeSearch: string
  topicMenuHead: string
  languageMenuHead: string
  countryMenuHead: string
  privacyPolicy: string
  conditionsUse: string
  reportProblem: string
  america: string
  apac: string
  europe: string
  latam: string
}

const resources: Resource = {
  'en': {
    translation: enTranslation as UILabels,
  },
  'es-US': {
    translation: esUSTranslation as UILabels,
  },
  'es-MX': {
    translation: esMXTranslation as UILabels,
  },
  'es-ES': {
    translation: esESTranslation as UILabels,
  },
  'fr-FR': {
    translation: frFRTranslation as UILabels,
  },
  'fr-CA': {
    translation: frCATranslation as UILabels,
  },
  'jp': {
    translation: jpTranslation as UILabels,
  },
  'de': {
    translation: deTranslation as UILabels,
  },
  'pt': {
    translation: ptTranslation as UILabels,
  },
}

const i18nOptions: InitOptions = {
  resources,
  lng: 'en',
  fallbackLng: {
    en: ['en'],
  },
  keySeparator: '.', // allow for nested translations
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['cookie', 'htmlTag', 'path'],
  },
}

const i18nInstance = i18next.createInstance()

void i18nInstance.use(LanguageDetector).use(initReactI18next).init(i18nOptions)

export default i18nInstance

export const changeLanguage = (
  language: string,
  country?: string,
): Promise<i18next.TFunction> => {
  const locale = country
    ? `${language.toLowerCase()}-${country.toUpperCase()}`
    : language.toLowerCase()
  return i18nInstance.changeLanguage(locale)
}

export const getLanguage = (): string => i18nInstance.language
