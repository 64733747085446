import React from 'react'

const MenuTopic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="11"
    viewBox="0 0 17 11"
  >
    <path
      id="Polygon_1"
      data-name="Polygon 1"
      d="M7.709,1.024a1,1,0,0,1,1.583,0l6.464,8.365A1,1,0,0,1,14.964,11H2.036a1,1,0,0,1-.791-1.611Z"
      transform="translate(17 11) rotate(180)"
      fill="#f5f5f5"
    />
  </svg>
)

export default MenuTopic
