import React, { FC, useCallback } from 'react'

import { useRoute } from '../context'

import { useTranslation } from '../hooks'

const ReportClose: FC = () => {
  const { t } = useTranslation()

  const { setIsReportVisible } = useRoute()

  const handleClick = useCallback((): void => {
    setIsReportVisible(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <button
      className="reportbox--close"
      type="button"
      title={t('closeReport')}
      onClick={handleClick}
    >
      <span />
      <span />
      <span />
    </button>
  )
}

export default ReportClose
