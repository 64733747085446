import React from 'react'

const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.38"
    height="26.397"
    viewBox="0 0 26.38 26.397"
  >
    <path
      id="Amazon_Search_Icon"
      d="M26.575,24.91l-7.02-7.02A10.6,10.6,0,0,0,21.8,11.431a10.6,10.6,0,0,0-21.2,0A10.607,10.607,0,0,0,11.13,21.961a10.664,10.664,0,0,0,6.318-2.106l7.02,7.02a1.357,1.357,0,0,0,1.966,0A1.257,1.257,0,0,0,26.575,24.91ZM3.408,11.431A7.791,7.791,0,0,1,11.13,3.708a7.791,7.791,0,0,1,7.722,7.722,7.791,7.791,0,0,1-7.722,7.722A7.7,7.7,0,0,1,3.408,11.431Z"
      transform="translate(-0.6 -0.9)"
      fill="#fff"
    />
  </svg>
)

export default Search
