import React, {
  FC,
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
} from 'react'
import { navigate } from 'gatsby'

import { WindowLocation } from '@reach/router'

import { topicMenuOrder } from '../config/menus'

export enum PageType {
  IndexPage = 'index',
  CountryTemplate = 'country-select',
  LandingTemplate = 'landing',
  TopicTemplate = 'topic',
  SearchResultsTemplate = 'search-results',
  ReportPage = 'report',
  NotFoundPage = 'error--notfound',
}

export type PageTypeIndex = keyof typeof PageType

export type PageTypeValue = `${PageType}`

export interface Localized {
  country: string
  language: string
  slug: string
}

interface RouteProviderProps extends Localized {
  component: PageTypeIndex
  children: React.ReactNode
}

interface RouteContextProps extends Localized {
  topic: string
  pageType: PageType | undefined
  isReportVisible: { enable?: boolean; url?: WindowLocation['pathname'] }
  setIsReportVisible: (
    enable: boolean,
    url?: WindowLocation['pathname'],
  ) => void
}

interface States extends Localized {
  currentTopic?: string
  pageType?: PageType | undefined
  previousPageType?: PageType | undefined
  isReportVisible?: { enable?: boolean; url?: WindowLocation['pathname'] }
}

type Actions =
  | { type: 'SET_COUNTRY'; payload: string }
  | { type: 'SET_LANGUAGE'; payload: string }
  | { type: 'SET_TOPIC'; payload: string }
  | { type: 'SET_SLUG'; payload: string }
  | { type: 'SET_PAGE_TYPE'; payload: PageType | undefined }
  | {
      type: 'SET_REPORT_VISIBLE'
      payload: { enable?: boolean; url?: WindowLocation['pathname'] }
    }

function reducer(state: States, action: Actions): States {
  switch (action.type) {
    case 'SET_COUNTRY':
      return { ...state, country: action.payload }
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload }
    case 'SET_SLUG':
      let updatedState = { ...state, slug: action.payload }
      if (topicMenuOrder.includes(action.payload)) {
        updatedState = { ...updatedState, currentTopic: action.payload }
      }
      return updatedState
    case 'SET_TOPIC':
      return { ...state, currentTopic: action.payload }
    case 'SET_PAGE_TYPE':
      return {
        ...state,
        previousPageType: state.pageType,
        pageType: action.payload,
      }
    case 'SET_REPORT_VISIBLE':
      return { ...state, isReportVisible: action.payload }
    default:
      throw new Error('Invalid action type')
  }
}

const RouteContext = createContext<RouteContextProps | undefined>(undefined)

export const RouteProvider: FC<RouteProviderProps> = ({
  country,
  language,
  slug,
  component,
  children,
}) => {
  const [state, dispatch] = useReducer<React.Reducer<States, Actions>>(
    reducer,
    {
      country,
      language,
      slug,
      pageType: PageType[component],
    },
  )

  useEffect(() => {
    dispatch({ type: 'SET_COUNTRY', payload: country })
  }, [country])

  useEffect(() => {
    dispatch({ type: 'SET_LANGUAGE', payload: language })
  }, [language])

  useEffect(() => {
    dispatch({ type: 'SET_SLUG', payload: slug })
  }, [slug])

  useEffect(() => {
    dispatch({ type: 'SET_PAGE_TYPE', payload: PageType[component] })
  }, [component])

  const setIsReportVisible = useCallback(
    (enable: boolean, url?: WindowLocation['pathname']): void => {
      dispatch({ type: 'SET_REPORT_VISIBLE', payload: { enable, url } })

      if (!enable) {
        void navigate(state.isReportVisible?.url ?? `/${country}/${language}`, {
          replace: true,
        })
      }
    },
    [country, language, state.isReportVisible?.url],
  )

  return (
    <RouteContext.Provider
      value={{
        country: state.country,
        language: state.language,
        slug: state.slug,
        topic: state.currentTopic ?? '',
        pageType: state.pageType ?? state.previousPageType,
        isReportVisible: state.isReportVisible ?? {},
        setIsReportVisible,
      }}
    >
      {children}
    </RouteContext.Provider>
  )
}

export const useRoute = (): RouteContextProps => {
  const context = useContext(RouteContext)
  if (!context) {
    throw new Error('useRoute must be used within an RouteProvider')
  }
  return context
}
