import React, { FC, useCallback } from 'react'
import { Link } from 'gatsby'

import { useRoute } from '../context'

import { useTranslation, useLocation } from '../hooks'

const docLinks = [
  {
    key: 'privacyPolicy',
    url: 'https://www.amazon.com/gp/help/customer/display.html?nodeId=468496&ref_=footer_privacy',
  },
  {
    key: 'conditionsUse',
    url: 'https://www.amazon.com/gp/help/customer/display.html?nodeId=508088',
  },
]

const Footer: FC = () => {
  const { country, language, setIsReportVisible } = useRoute()

  const { t } = useTranslation()

  const location = useLocation()

  const handleClick = useCallback((): void => {
    setIsReportVisible(true, location.pathname)
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <footer>
      <nav className="menu menu--footer">
        <ul>
          <li>{t('copyright')}</li>
          {docLinks.map((link, idx) => (
            <li key={idx}>
              <a href={link.url} lang="en" target="_blank" rel="noreferrer">
                {t(link.key)}
              </a>
            </li>
          ))}
          <li>
            <Link
              to={`/${country}/${language}/report`}
              activeStyle={{ color: '#a5a5a5' }}
              onClick={handleClick}
            >
              {t('reportProblem')}
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
