import React, { FC, useRef, useCallback, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
// import { DevTool } from '@hookform/devtools'

import { useRoute, useMenus } from '../context'

import { ReportClose } from '../components'

import { useOutsideClick } from '../hooks'

const url =
  process.env.GATSBY_REPORT_PROBLEM_URL ??
  'https://report-problem.reachoutnow.com'

interface FormData {
  page: string
  message: string
}

const ReportProblem: FC<{ path: string }> = () => {
  if (!process.env.GATSBY_REPORT_PROBLEM_URL) {
    throw new Error(
      'The GATSBY_REPORT_PROBLEM_URL environment variable is not set!',
    )
  }

  const reportBoxRef = useRef<HTMLDivElement>(null)

  const { country, language, isReportVisible, setIsReportVisible } = useRoute()
  const { topics } = useMenus()

  const defaultMessage = 'Please write a brief description about the problem.'

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState,
    // control
  } = useForm<FormData>({
    defaultValues: {
      page: isReportVisible.url ?? '',
      message: defaultMessage,
    },
    mode: 'onChange',
  })

  const messageValue = watch('message')
  const { errors, isSubmitting, isSubmitSuccessful } = formState

  const submitReport: SubmitHandler<FormData> = useCallback(
    async (data): Promise<void> => {
      const response = await fetch(`${url}/submit-report`, {
        method: 'POST',
        body: JSON.stringify({
          page: data.page,
          message: data.message,
        }),
      })

      if (response.status !== 200) {
        // eslint-disable-next-line no-console
        console.error(
          'Error sending email',
          response.type,
          response.status,
          response.statusText,
        )
      }
    },
    [],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
      const target = e.currentTarget
      setIsReportVisible(true, target.value)
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>): void => {
      const message = getValues('message')
      if (message === defaultMessage) {
        const target = e.currentTarget
        target.select()

        setValue('message', '')
      }
    },
    [getValues, setValue],
  )

  const handleBlur = useCallback((): void => {
    if (!messageValue) {
      setValue('message', defaultMessage)
    }
  }, [messageValue, setValue])

  useEffect(() => {
    if (isReportVisible.url) {
      setValue('page', isReportVisible.url)
    }
  }, [isReportVisible.url, setValue])

  useEffect(() => {
    if (isSubmitSuccessful) {
      const closeReportOnSuccess = setTimeout(() => {
        setIsReportVisible(false)
        clearTimeout(closeReportOnSuccess)
      }, 3000)
    }
  }, [isSubmitSuccessful]) // eslint-disable-line react-hooks/exhaustive-deps

  useOutsideClick(reportBoxRef, () => {
    setIsReportVisible(false)
  })

  return (
    <div ref={reportBoxRef} className="reportbox">
      <ReportClose />

      <h4>Report Problem</h4>
      <h5>Sorry you are experiencing trouble with this site.</h5>
      <p>
        Please use this form to report problems with resources, links or
        technical issues.
      </p>

      <form onSubmit={onPromise(handleSubmit(submitReport))}>
        <label>
          <span>Page:</span>
          {isReportVisible.url ? (
            <input
              {...register('page', {
                required: {
                  value: true,
                  message: 'Page is required',
                },
                validate: {
                  notEmpty: value =>
                    value.trim().length > 0 || 'Page cannot be empty',
                },
              })}
              className={errors.page ? 'error' : ''}
              aria-invalid={errors.page ? 'true' : 'false'}
              onChange={handleChange}
            />
          ) : (
            <select
              {...register('message', {
                required: {
                  value: true,
                  message: 'Message is required',
                },
                validate: {
                  notDefaultMessage: value =>
                    value !== defaultMessage ||
                    'Default message cannot be submitted',
                },
              })}
              className={errors.page ? 'error' : ''}
              aria-invalid={errors.page ? 'true' : 'false'}
              onChange={handleChange}
            >
              <option
                key={`/${country}/${language}`}
                value={`/${country}/${language}`}
              >
                Main landing page
              </option>
              {topics.map(topic => (
                <option key={topic.slug} value={topic.slug}>
                  {topic.topicTitle}
                </option>
              ))}
            </select>
          )}
        </label>
        <label>
          <span>Message:</span>
          <textarea
            {...register('message', {
              required: true,
              validate: value => value !== defaultMessage,
            })}
            aria-invalid={errors.message ? 'true' : 'false'}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </label>
        <button type="submit">
          {isSubmitSuccessful ? 'Submitted' : 'Submit'}
        </button>
      </form>

      {/* <DevTool control={control} /> */}

      <p className={`message ${Object.keys(errors).length > 0 ? 'error' : ''}`}>
        {isSubmitting && <>Sending...</>}

        {isSubmitSuccessful && <>We appreciate your help.</>}

        {Object.keys(errors).length > 1 ? (
          <>All fields are required</>
        ) : (
          <>
            {errors.page && <>Select a page</>}

            {errors.message && <>Default text cannot be submitted</>}
          </>
        )}
      </p>
    </div>
  )
}

export default ReportProblem

// Fun https://github.com/orgs/react-hook-form/discussions/8020
function onPromise<T>(promise: (event: React.SyntheticEvent) => Promise<T>) {
  return (event: React.SyntheticEvent): void => {
    void promise(event)

    // if (promise) {
    //   promise(event).catch(error => {
    //     // eslint-disable-next-line no-console
    //     console.log('Unexpected error', error)
    //   })
    // }
  }
}
