import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useRoute } from '../context'

import { getLanguage } from '../utils'

import { countryMenuRegionOrder, topicMenuOrder } from '../config/menus'

import type { Localized, Topic, Language, Country } from '../types'

type TopicMenuType = Localized & Pick<Topic, 'topicTitle'>

export type LanguageMenuType = Localized &
  Pick<Language, 'languageName'> & {
    active: boolean
  }

type CountryMenuType = Pick<Localized, 'country' | 'slug'> &
  Pick<Country, 'defaultLanguage' | 'languages'> &
  Pick<Language, 'countryName' | 'regionKey'>

interface MenusProviderProps {
  children: React.ReactNode
}

interface CombinedData {
  allTopicYaml: {
    nodes: TopicMenuType[]
  }
  allLanguageYaml: {
    nodes: LanguageMenuType[]
  }
  allCountryYaml: {
    nodes: CountryMenuType[]
  }
}

interface CountryMenuGrouped {
  region: string
  countries: CountryMenuType[]
}

interface MenusContextProps {
  mainProps: string
  topics: TopicMenuType[]
  setTopics: React.Dispatch<React.SetStateAction<TopicMenuType[]>>
  languages: LanguageMenuType[]
  setLanguages: React.Dispatch<React.SetStateAction<LanguageMenuType[]>>
  countries: CountryMenuGrouped[]
  setCountries: React.Dispatch<React.SetStateAction<CountryMenuGrouped[]>>
  currentCountry: CountryMenuType | undefined
  setCurrentCountry: React.Dispatch<
    React.SetStateAction<CountryMenuType | undefined>
  >
  isCountryMenuVisible: boolean
  setIsCountryMenuVisible: React.Dispatch<React.SetStateAction<boolean>>
  isTopicMenuVisible: boolean
  setIsTopicMenuVisible: React.Dispatch<React.SetStateAction<boolean>>
  isMobileMenuVisible: boolean
  setIsMobileMenuVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const MenusContext = createContext<MenusContextProps | undefined>(undefined)

export const MenusProvider: FC<MenusProviderProps> = ({ children }) => {
  const [topics, setTopics] = useState<TopicMenuType[]>([])
  const [languages, setLanguages] = useState<LanguageMenuType[]>([])
  const [countries, setCountries] = useState<CountryMenuGrouped[]>([])
  const [currentCountry, setCurrentCountry] = useState<CountryMenuType>()
  const [isCountryMenuVisible, setIsCountryMenuVisible] =
    useState<boolean>(false)
  const [isTopicMenuVisible, setIsTopicMenuVisible] = useState<boolean>(false)
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState<boolean>(false)

  const { country, language, topic } = useRoute()

  const data: CombinedData = useStaticQuery(graphql`
    query LayoutQuery {
      allTopicYaml(filter: { sourceInstanceName: { eq: "yaml" } }) {
        nodes {
          topicTitle
          slug
          country
          language
        }
      }
      allLanguageYaml(filter: { sourceInstanceName: { eq: "yaml" } }) {
        nodes {
          languageName
          country
          language
        }
      }
      allCountryYaml(filter: { sourceInstanceName: { eq: "yaml" } }) {
        nodes {
          country
          defaultLanguage
          languages {
            country
            language
            countryName
            regionKey
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!country || !language) return

    const topicsArray: TopicMenuType[] = data.allTopicYaml.nodes
      .filter(node => node.country === country && node.language === language)
      .sort((a, b) => {
        const indexA = topicMenuOrder.indexOf(a.slug)
        const indexB = topicMenuOrder.indexOf(b.slug)
        return indexA - indexB
      })
      .flatMap(node => ({
        topicTitle: node.topicTitle,
        slug: `/${country}/${language}/${node.slug}`,
        country: node.country,
        language: node.language,
      }))

    setTopics(topicsArray)
  }, [data, country, language])

  useEffect(() => {
    if (!country || !language) return

    // Extract the data
    const allLanguages = data.allLanguageYaml.nodes

    // Fetch the default languages for each country
    const defaultLanguages: Record<string, string> = {}
    data.allCountryYaml.nodes.forEach(countryNode => {
      defaultLanguages[countryNode.country] = countryNode.defaultLanguage
    })

    const languagesArray: LanguageMenuType[] = allLanguages
      .filter(node => node.country === country)
      .map(node => {
        const active = node.language === language

        return {
          languageName: node.languageName,
          country,
          language: node.language,
          slug: topic
            ? `/${country}/${node.language}/${topic}`
            : `/${country}/${node.language}`,
          active,
        }
      })
      .sort((a, b) => {
        const isADefault = defaultLanguages[a.country] === a.language
        const isBDefault = defaultLanguages[b.country] === b.language

        if (isADefault && !isBDefault) return -1
        if (!isADefault && isBDefault) return 1
        return 0
      })

    setLanguages(languagesArray)
  }, [data, country, language, topic])

  useEffect(() => {
    if (!country || !language) return

    // 1. Find the data object for the currently selected country.
    const currentCountryObject = data.allCountryYaml.nodes.find(
      (node): node is CountryMenuType => node.country === country,
    )

    // 2. Helper function to create a country object with the desired shape.
    const createCountryObject = (node: CountryMenuType): CountryMenuType => {
      // 2.1 Get the detected language from i18n.
      const detectedLanguage = getLanguage()

      // 2.2 Find the language entry for the default language of the country.
      const defaultLanguage =
        node.languages.find(lang => lang.language === node.defaultLanguage) ??
        ({
          countryName: node.defaultLanguage,
          country: detectedLanguage,
          language: detectedLanguage,
        } as Language)

      // 2.3 Extract the country name.
      const countryName = defaultLanguage.countryName

      // 2.4 Return the final object for the country.
      return {
        countryName,
        regionKey: defaultLanguage.regionKey,
        slug: topic
          ? `/${node.country}/${node.defaultLanguage}/${topic}`
          : `/${node.country}/${node.defaultLanguage}`,
        defaultLanguage: node.defaultLanguage,
        country: node.country,
        languages: node.languages,
      }
    }

    // 3. Build the final array of the current country
    const currentCountry: CountryMenuType | undefined = currentCountryObject
      ? createCountryObject(currentCountryObject)
      : undefined

    // 4.1 Group countries by region
    const groupedByRegion = data.allCountryYaml.nodes.reduce<
      Record<string, CountryMenuType[]>
    >((acc, node) => {
      const country = createCountryObject(node)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!acc[country.regionKey]) {
        acc[country.regionKey] = []
      }
      acc[country.regionKey].push(country)
      return acc
    }, {})

    // 4.2 Sort regions by the order defined in the context
    const groupedByRegionSorted = Object.keys(groupedByRegion)
      .sort((a, b) => {
        const indexA = countryMenuRegionOrder.indexOf(a)
        const indexB = countryMenuRegionOrder.indexOf(b)
        return indexA - indexB
      })
      .reduce<Record<string, CountryMenuType[]>>((sortedObj, key) => {
        sortedObj[key] = groupedByRegion[key]
        return sortedObj
      }, {})

    // 4.3 Convert this grouped object into an array
    const countriesGroupedArray: {
      region: string
      countries: CountryMenuType[]
    }[] = Object.entries(groupedByRegionSorted).map(
      ([regionName, countries]) => ({
        region: regionName,
        countries: countries.sort((a, b) =>
          a.countryName.localeCompare(b.countryName),
        ),
      }),
    )

    // 5. Update the state with the newly formed countries arrays.
    setCountries(countriesGroupedArray)
    setCurrentCountry(currentCountry)
  }, [data, country, language, topic])

  const mainProps = useMemo(() => {
    if (isMobileMenuVisible) {
      return 'blurry overlay'
    }
    return ''
  }, [isMobileMenuVisible])

  return (
    <MenusContext.Provider
      value={{
        mainProps,
        topics,
        setTopics,
        languages,
        setLanguages,
        countries,
        setCountries,
        currentCountry,
        setCurrentCountry,
        isCountryMenuVisible,
        setIsCountryMenuVisible,
        isTopicMenuVisible,
        setIsTopicMenuVisible,
        isMobileMenuVisible,
        setIsMobileMenuVisible,
      }}
    >
      {children}
    </MenusContext.Provider>
  )
}

export const useMenus = (): MenusContextProps => {
  const context = useContext(MenusContext)
  if (!context) {
    throw new Error('useMenus must be used within an MenusProvider')
  }
  return context
}

export const useMainProps = (): MenusContextProps['mainProps'] => {
  const { mainProps } = useMenus()
  return mainProps
}

export default MenusContext
