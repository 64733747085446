import { RefObject, useEffect } from 'react'

// type SetStateAction<S> = S | ((prevState: S) => S)

// type StateSetters<T extends Record<string, any>> = {
//   [K in keyof T]: (value: SetStateAction<T[K]>) => void
// }

// export function useOutsideClick<T extends Record<string, any>>(
//   ref: RefObject<HTMLElement | null>,
//   setters: StateSetters<T>,
//   callback: (setters: StateSetters<T>) => void,
// ): void {
//   useEffect(() => {
//     function handleClickOutside(event: MouseEvent): void {
//       if (ref.current && !ref.current.contains(event.target as Node)) {
//         callback(setters)
//       }
//     }

//     document.addEventListener('click', handleClickOutside)
//     return () => {
//       document.removeEventListener('click', handleClickOutside)
//     }
//   }, [ref, setters, callback])
// }

type SetStateAction<S> = S | ((prevState: S) => S)

export function useOutsideClick<S>(
  ref: RefObject<HTMLElement | null>,
  callback: (value: SetStateAction<S>) => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(state => state)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref, callback])
}

// export function useOutsideClick(
//   ref: RefObject<HTMLElement | null>,
//   callback: any,
// ): void {
//   useEffect(() => {
//     function handleClickOutside(event: MouseEvent) {
//       if (ref.current && !ref.current.contains(event.target as Node)) {
//         callback()
//       }
//     }

//     document.addEventListener('click', handleClickOutside)
//     return (): void => {
//       document.removeEventListener('click', handleClickOutside)
//     }
//   }, [ref, callback])
// }
