import React from 'react'

const Home = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="142.552"
    height="89.089"
    viewBox="0 0 142.552 89.089"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_28"
          data-name="Rectangle 28"
          width="142.553"
          height="89.089"
          fill="#f5f5f5"
        />
      </clipPath>
    </defs>
    <g id="Group_18" data-name="Group 18" transform="translate(0 0)">
      <g
        id="Group_17"
        data-name="Group 17"
        transform="translate(0 0)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_34"
          data-name="Path 34"
          d="M1.691,45.55A1.524,1.524,0,0,1,.64,45.195a1.409,1.409,0,0,1-.319-1.069V7.791A1.4,1.4,0,0,1,.64,6.722a1.515,1.515,0,0,1,1.034-.3H5.627A1.407,1.407,0,0,1,7.159,7.613l.392,1.424A12.135,12.135,0,0,1,11.5,6.491a12.642,12.642,0,0,1,4.738-.925,10.164,10.164,0,0,1,8.336,3.882,16.362,16.362,0,0,1,3.1,10.492,19.107,19.107,0,0,1-1.53,7.926A12.462,12.462,0,0,1,22,33.082a10.253,10.253,0,0,1-6.021,1.782,12.746,12.746,0,0,1-4.274-.711,10.275,10.275,0,0,1-3.437-1.959V44.161a1.491,1.491,0,0,1-.3,1.069,1.491,1.491,0,0,1-1.068.3ZM13.679,28.879a5.132,5.132,0,0,0,4.506-2.085,11.646,11.646,0,0,0,1.443-6.535A11.948,11.948,0,0,0,18.2,13.651a5.107,5.107,0,0,0-4.541-2.085,10.431,10.431,0,0,0-5.345,1.478V27.384A10.038,10.038,0,0,0,13.661,28.9Z"
          transform="translate(0.364 6.489)"
          fill="#f5f5f5"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M16.811,34.006a1.176,1.176,0,0,1-1.372-.941,1.2,1.2,0,0,1,0-.431V7.5a1.414,1.414,0,0,1,.321-1.068,1.515,1.515,0,0,1,1.034-.3H20.73a1.4,1.4,0,0,1,1.53,1.192l.713,2.922a15.584,15.584,0,0,1,4.133-3.42,8.9,8.9,0,0,1,4.133-1.014H32a1.6,1.6,0,0,1,1.086.3,1.405,1.405,0,0,1,.321,1.068v4.595a1.493,1.493,0,0,1-.3,1.034,1.414,1.414,0,0,1-1.068.321h-2.51a16.028,16.028,0,0,0-3.065.355A15.073,15.073,0,0,0,23.4,14.4V32.652a1.523,1.523,0,0,1-.3,1.034,1.411,1.411,0,0,1-1.068.321Z"
          transform="translate(18 6.776)"
          fill="#f5f5f5"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M29.839,8.563a4.762,4.762,0,0,1-3.331-1.138,4.01,4.01,0,0,1-1.264-3.119,4.009,4.009,0,0,1,1.264-3.134,5.345,5.345,0,0,1,6.68,0,4.018,4.018,0,0,1,1.266,3.117,4.012,4.012,0,0,1-1.266,3.117,4.744,4.744,0,0,1-3.349,1.157M27.22,40.8a1.176,1.176,0,0,1-1.372-.941,1.2,1.2,0,0,1,0-.431V14.28a1.419,1.419,0,0,1,.321-1.068,1.524,1.524,0,0,1,1.034-.3h5.254a1.5,1.5,0,0,1,1.068.3,1.5,1.5,0,0,1,.3,1.068V39.43a1.493,1.493,0,0,1-.3,1.034,1.414,1.414,0,0,1-1.068.321Z"
          transform="translate(29.462 0)"
          fill="#f5f5f5"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M33.707,34.306a1.176,1.176,0,0,1-1.372-.941,1.2,1.2,0,0,1,0-.431V7.8a1.414,1.414,0,0,1,.321-1.068,1.515,1.515,0,0,1,1.034-.3h3.936a1.407,1.407,0,0,1,1.532,1.192L39.6,9.1a23.638,23.638,0,0,1,5.219-2.709,14.81,14.81,0,0,1,4.738-.819,7.359,7.359,0,0,1,6.946,3.563,22.471,22.471,0,0,1,5.254-2.672,16.087,16.087,0,0,1,5.1-.819,7.978,7.978,0,0,1,5.93,2.137,8.1,8.1,0,0,1,2.1,5.949V32.951a1.512,1.512,0,0,1-.3,1.034,1.405,1.405,0,0,1-1.068.321H68.333a1.176,1.176,0,0,1-1.372-.941,1.2,1.2,0,0,1,0-.431V15.409q0-3.722-3.331-3.723a13.871,13.871,0,0,0-5.967,1.426v19.84a1.49,1.49,0,0,1-.3,1.034,1.405,1.405,0,0,1-1.068.321H51.055a1.174,1.174,0,0,1-1.37-.941,1.111,1.111,0,0,1,0-.431V15.409q0-3.722-3.331-3.723a13.35,13.35,0,0,0-6.021,1.478v19.77a1.515,1.515,0,0,1-.3,1.034,1.414,1.414,0,0,1-1.068.321Z"
          transform="translate(37.724 6.477)"
          fill="#f5f5f5"
        />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M68.3,35.141a13.929,13.929,0,0,1-10.421-3.723,14.578,14.578,0,0,1-3.561-10.685A15.825,15.825,0,0,1,57.955,9.6,13.362,13.362,0,0,1,68.3,5.556a11.854,11.854,0,0,1,7.961,2.458,8.193,8.193,0,0,1,2.867,6.591,7.016,7.016,0,0,1-3.1,6.2,16.211,16.211,0,0,1-9.1,2.1,21.636,21.636,0,0,1-5.342-.6A7.4,7.4,0,0,0,63.8,27.535a8.724,8.724,0,0,0,5.717,1.584,22.762,22.762,0,0,0,2.974-.2,36.909,36.909,0,0,0,4.025-.856H77.1c.62,0,.928.423.928,1.266v2.512a2.282,2.282,0,0,1-.249,1.229,2.009,2.009,0,0,1-.962.622A23.467,23.467,0,0,1,68.3,35.141M66.522,17.988a7.455,7.455,0,0,0,4.042-.854,2.921,2.921,0,0,0,1.266-2.6q0-3.44-4.1-3.437-5.257,0-6.075,6.448a25.072,25.072,0,0,0,4.829.444Z"
          transform="translate(63.365 6.461)"
          fill="#f5f5f5"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M128.8,39.239C113.2,50.745,90.577,56.855,71.11,56.855A104.489,104.489,0,0,1,.63,30.013c-1.459-1.318-.158-3.117,1.6-2.1A141.981,141.981,0,0,0,72.749,46.595a140.426,140.426,0,0,0,53.718-10.988c2.709-1.123,4.918,1.656,2.334,3.632"
          transform="translate(0 32.233)"
          fill="#f5f5f5"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M72.977,33.383c-1.994-2.547-13.18-1.212-18.221-.6-1.532.178-1.782-1.14-.392-2.1,8.906-6.27,23.565-4.454,25.256-2.352s-.444,16.76-8.906,23.761c-1.281,1.069-2.51.5-1.94-.925,1.905-4.792,6.127-15.3,4.2-17.777"
          transform="translate(62.395 30.768)"
          fill="#f5f5f5"
        />
      </g>
    </g>
  </svg>
)

export default Home
