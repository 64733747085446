import React, { FC, useCallback } from 'react'

import { useTranslation } from '../hooks'

interface HamburgerProps {
  onClick: (state: boolean) => void
  state: boolean
}

const Hamburger: FC<HamburgerProps> = ({ onClick, state }) => {
  const { t } = useTranslation()

  const handleClick = useCallback((): void => {
    onClick(!state)
  }, [state, onClick])

  return (
    <button
      className={`menu--hamburger-icon ${
        state ? 'menu--hamburger-icon--focus' : ''
      }`}
      type="button"
      title={t('mainNavigation')}
      onClick={handleClick}
    >
      <span />
      <span />
      <span />
    </button>
  )
}

export default Hamburger
