import React, { FC } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

interface TransitionProps {
  className: string
  location: {
    pathname: string
  }
  children: React.ReactNode
}

const Transition: FC<TransitionProps> = ({ className, location, children }) => {
  return (
    <AnimatePresence mode="wait">
      <motion.main
        key={location.pathname}
        className={className}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.main>
    </AnimatePresence>
  )
}

export default Transition
