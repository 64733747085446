import React, { FC } from 'react'
// import React, { FC, useState } from 'react'
// import { useSwipeable } from 'react-swipeable'

import {
  useRoute,
  useMenus,
  useSearch,
  PageType as PageTypeEnum,
} from '../context'

import {
  Brand,
  Footer,
  SearchBox,
  TopicMenu,
  LanguageMenu,
  CountryMenu,
  Hamburger,
} from '../components'

const HamburgerMenu: FC = () => {
  const { pageType } = useRoute()

  const { setIsMobileMenuVisible, isMobileMenuVisible } = useMenus()

  const { searchBoxState } = useSearch()

  // const [swiped, setSwiped] = useState<boolean>(false)
  // const [transform, setTransform] = useState<number>(0)
  // console.log('transform', transform)

  // const handlers = useSwipeable({
  //   onSwipedLeft: eventData => {
  //     // setSwiped(true)
  //     setTransform(eventData.absX)
  //   },
  //   onTouchEndOrOnMouseUp: () => {
  //     if (transform > 0) {
  //       setIsMobileMenuVisible(false)
  //       setTransform(0)
  //     }
  //     // if (swiped) {
  //     //   setIsMobileMenuVisible(false)
  //     //   setSwiped(false)
  //     //   setTransform(0)
  //     // }
  //   },
  //   trackMouse: true,
  // })

  return (
    <nav
      className={`menu menu--hamburger--fullscreen ${
        isMobileMenuVisible ? 'menu--hamburger--fullscreen--active' : ''
      }`}
      // {...handlers}
      // style={{ transform: `translateX(-${transform}px)` }}
    >
      <div className="menu--hamburger--fullscreen--header">
        <SearchBox />
        {!searchBoxState.has('focus') &&
          (pageType === PageTypeEnum.LandingTemplate ? (
            <>
              <LanguageMenu />
              <CountryMenu />
            </>
          ) : (
            <>
              <Brand />
              <Hamburger
                state={isMobileMenuVisible}
                onClick={setIsMobileMenuVisible}
              />
            </>
          ))}
      </div>

      <TopicMenu hamburger />
      <CountryMenu hamburger />
      <LanguageMenu hamburger />
      <Footer />
    </nav>
  )
}

export default HamburgerMenu
