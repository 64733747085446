import React, { FC, useRef, useState, useCallback } from 'react'
import { Link } from 'gatsby'
// import { useSwipeable } from 'react-swipeable'

import { useMenus } from '../context'

import { Hamburger } from '../components'

import { useTranslation } from '../hooks'
// import { useTranslation, useMobileDesign } from '../hooks'

import { MenuCountry, MenuCountryActive } from '../icons'

const CountryMenu: FC<{ hamburger?: boolean }> = ({ hamburger }) => {
  const { t } = useTranslation()
  // const isMobile = useMobileDesign()

  const {
    countries,
    currentCountry,
    isCountryMenuVisible,
    setIsCountryMenuVisible,
    setIsMobileMenuVisible,
  } = useMenus()

  const menuRef = useRef<HTMLElement>(null)

  const [isFocus, setIsFocus] = useState<boolean>(false)
  // const [swiped, setSwiped] = useState<boolean>(false)

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => {
  //     setSwiped(isMobile)
  //   },
  //   onTouchEndOrOnMouseUp: () => {
  //     if (swiped) {
  //       setIsMobileMenuVisible(false)
  //       setSwiped(false)
  //     }
  //   },
  //   trackMouse: true,
  // })

  const handleMouseEnter: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((): void => {
      setIsFocus(true)
    }, [])

  const handleMouseLeave: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((): void => {
      setIsFocus(false)
    }, [])

  const handleNavMouseLeave: React.MouseEventHandler<HTMLDivElement> =
    useCallback((): void => {
      setIsCountryMenuVisible(false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = useCallback((): void => {
    setIsCountryMenuVisible(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMouseClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((): void => {
      handleClick()
    }, [handleClick])

  const handleTouch: React.TouchEventHandler<HTMLButtonElement> = useCallback(
    (e): void => {
      e.preventDefault()
      handleClick()
    },
    [handleClick],
  )

  const handleKey: React.KeyboardEventHandler<HTMLButtonElement> = useCallback(
    (e): void => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleClick()
      }
    },
    [handleClick],
  )

  const handleLinkClick = useCallback((): void => {
    setIsCountryMenuVisible(false)
    setIsMobileMenuVisible(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <nav
      ref={menuRef}
      className={`menu ${
        hamburger ? 'menu--hamburger--country' : 'menu--country'
      } ${isFocus ? 'menu--country--focus' : ''}`}
    >
      <div
        className={`menu--header ${
          hamburger
            ? 'menu--hamburger--header menu--hamburger--country--header'
            : 'menu--country--header'
        }`}
      >
        <button
          className="menu--header--button"
          type="button"
          title={t('countryMenuHead')}
          disabled={isCountryMenuVisible}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleMouseClick}
          onTouchEnd={handleTouch}
          onKeyUp={handleKey}
        >
          {hamburger ? (
            <div className="mb-4 flex flex-row items-center">
              <MenuCountry />
              <div>
                <span className="menu--header--label menu--country--header--label ml-3">
                  {t('countryMenuHead')}
                </span>
              </div>
            </div>
          ) : (
            <>
              {isFocus ? <MenuCountryActive /> : <MenuCountry />}
              <div>
                <span className="menu--header--label menu--country--header--label">
                  {t('countryMenuHead')}
                </span>
                <span className="menu--header--current-country">
                  {!isFocus && currentCountry && currentCountry.countryName}
                </span>
              </div>
            </>
          )}
        </button>
      </div>
      <div
        className={`menu menu--country--all ${
          isCountryMenuVisible ? 'menu--country--all--active' : ''
        }`}
        role="menu"
        tabIndex={-1}
        onMouseLeave={handleNavMouseLeave}
        // {...handlers}
      >
        <div className="menu--header menu--country--all--header">
          <Hamburger
            state={isCountryMenuVisible}
            onClick={setIsCountryMenuVisible}
          />
        </div>
        {countries.map(region => (
          <nav key={region.region}>
            <h3>{t(region.region)}</h3>
            <ul>
              {region.countries.map(country => (
                <li key={country.country}>
                  <Link
                    to={country.slug}
                    activeClassName="link--active"
                    title={country.countryName}
                    onClick={handleLinkClick}
                  >
                    {country.countryName}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        ))}
      </div>
    </nav>
  )
}

export default CountryMenu
