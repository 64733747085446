import React, { FC, useCallback } from 'react'
import { Link } from 'gatsby'

import { useRoute, useSearch } from '../context'

import { Home } from '../icons'

const Brand: FC = () => {
  const { country, language } = useRoute()

  const { searchBoxOrigin, setSearchBoxOrigin } = useSearch()

  const handleClick = useCallback((): void => {
    setSearchBoxOrigin('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="brand">
      <p>
        <Link
          to={
            searchBoxOrigin.length > 0
              ? searchBoxOrigin
              : `/${country}/${language}/`
          }
          aria-label="Home"
          activeClassName="link--active"
          onClick={handleClick}
        >
          <Home />
        </Link>
      </p>
    </div>
  )
}

export default Brand
