import React, { FC, useCallback } from 'react'

import { useSearch } from '../context'

import { useTranslation } from '../hooks'

const SearchClose: FC = () => {
  const { t } = useTranslation()

  const { searchBoxState, resetSearchBoxState } = useSearch()

  const handleClick = useCallback((): void => {
    resetSearchBoxState()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (searchBoxState.size === 0) return null

  return (
    <button
      className="searchbox--close"
      type="button"
      title={t('closeSearch')}
      onClick={handleClick}
    >
      <span />
      <span />
      <span />
    </button>
  )
}

export default SearchClose
