import { useEffect, useState } from 'react'

export const useMobileDesign = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  // // Check for window object during SSR and set initial state
  // const [isMobile, setIsMobile] = useState(() => {
  //   if (typeof window !== 'undefined') {
  //     return window.innerWidth <= 1200
  //   }
  //   return false
  // })

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 1200)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

export default useMobileDesign
