import React from 'react'

const MenuCountryActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.5"
    height="27.5"
    viewBox="0 0 27.5 27.5"
  >
    <g id="globe" transform="translate(0.75 0.75)">
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="13"
        cy="13"
        r="13"
        transform="translate(0 0)"
        fill="none"
        stroke="#1a98ff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <line
        id="Line_2"
        data-name="Line 2"
        y1="26"
        transform="translate(13.095)"
        fill="none"
        stroke="#1a98ff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <ellipse
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="8"
        cy="13"
        rx="8"
        ry="13"
        transform="translate(5 0)"
        fill="none"
        stroke="#1a98ff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        x2="25.358"
        transform="translate(0 13.095)"
        fill="none"
        stroke="#1a98ff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="Path_29"
        data-name="Path 29"
        d="M1.8,13.242C3.871,11.222,8.679,9.8,14.374,9.8c5.547,0,10.281,1.347,12.426,3.292"
        transform="translate(-1.351 -3.44)"
        fill="none"
        stroke="#1a98ff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M26.8,23.2c-2.071,2.02-6.953,3.442-12.574,3.442C8.679,26.642,3.945,25.3,1.8,23.35"
        transform="translate(-1.351 -6.813)"
        fill="none"
        stroke="#1a98ff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default MenuCountryActive
