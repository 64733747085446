import React, { FC, useState, useCallback } from 'react'
import { Link } from 'gatsby'

import { useMenus } from '../context'

import { useTranslation } from '../hooks'

import type { LanguageMenuType } from '../types'

const LanguageMenu: FC<{ hamburger?: boolean }> = ({ hamburger }) => {
  const { t } = useTranslation()

  const { languages } = useMenus()

  const [isFocus, setIsFocus] = useState<boolean>(false)

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> =
    useCallback((): void => {
      setIsFocus(true)
    }, [])

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> =
    useCallback((): void => {
      setIsFocus(false)
    }, [])

  const handleClick =
    (language: LanguageMenuType) =>
    (
      e:
        | React.MouseEvent<HTMLAnchorElement>
        | React.TouchEvent<HTMLAnchorElement>,
    ): void => {
      // If it's an active link, we prevent its default behavior
      if (language.active) {
        e.preventDefault()
      }

      // If uncommented this causes the language switcher to fail
      // setIsMobileMenuVisible(false)
    }

  const handleKeyUp =
    (language: LanguageMenuType) =>
    (e: React.KeyboardEvent<HTMLAnchorElement>): void => {
      if ('key' in e) {
        if (e.key === 'Enter') {
          e.preventDefault()
          handleClick(language)
        }
      }
    }

  return (
    <nav
      className={`menu ${
        hamburger ? 'menu--hamburger--language' : 'menu--language'
      } ${isFocus ? 'menu--language--focus' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`menu--header ${
          hamburger
            ? 'menu--hamburger--header menu--hamburger--language--header'
            : 'menu--language--header'
        } ${!hamburger ? 'visually-hidden' : ''}`}
      >
        <span className="menu--header--label">{t('languageMenuHead')}</span>
      </div>
      <ul>
        {languages.map(language => (
          <li key={language.language} lang={language.language}>
            {language.active ? (
              <span className="link--active link--disabled">
                {language.languageName}
              </span>
            ) : (
              <Link
                to={language.slug}
                activeClassName="link--active link--disabled"
                title={language.languageName}
                onClick={handleClick(language)}
                onTouchEnd={handleClick(language)}
                onKeyUp={handleKeyUp(language)}
              >
                {language.languageName}
              </Link>
            )}{' '}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default LanguageMenu
