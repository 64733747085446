import React, { FC, useEffect, useState } from 'react'

import { useRoute, useMenus, useSearch, PageType } from '../context'

import {
  Brand,
  SearchBox,
  TopicMenu,
  LanguageMenu,
  CountryMenu,
  HamburgerMenu,
  Hamburger,
} from '../components'

import { useMobileDesign } from '../hooks'

const Header: FC = () => {
  const [headerProps, setHeaderProps] = useState('')

  const { pageType } = useRoute()

  const { isMobileMenuVisible, setIsMobileMenuVisible } = useMenus()

  const { searchBoxState, searchBoxStatesClass } = useSearch()

  const isMobile = useMobileDesign()

  useEffect(() => {
    setHeaderProps(searchBoxStatesClass)
  }, [searchBoxStatesClass])

  return (
    <header className={headerProps}>
      {!isMobile ? (
        <>
          {(pageType === PageType.TopicTemplate ||
            pageType === PageType.SearchResultsTemplate ||
            pageType === PageType.NotFoundPage) && (
            <>
              <Brand />
              <TopicMenu />
            </>
          )}

          <div className="utility-nav">
            {pageType !== PageType.SearchResultsTemplate && <SearchBox />}

            {(pageType === PageType.LandingTemplate ||
              pageType === PageType.TopicTemplate ||
              pageType === PageType.NotFoundPage) && (
              <>
                <LanguageMenu />
                <CountryMenu />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {(pageType === PageType.LandingTemplate ||
            pageType === PageType.TopicTemplate ||
            pageType === PageType.NotFoundPage) && (
            <>
              <SearchBox />
              {!searchBoxState.has('focus') &&
                (pageType === PageType.LandingTemplate ? (
                  <>
                    <LanguageMenu />
                    <CountryMenu />
                  </>
                ) : (
                  <>
                    <Brand />
                    <Hamburger
                      state={isMobileMenuVisible}
                      onClick={setIsMobileMenuVisible}
                    />
                    <HamburgerMenu />
                  </>
                ))}
            </>
          )}

          {pageType === PageType.SearchResultsTemplate && <Brand />}
        </>
      )}
    </header>
  )
}

export default Header
