import React, { FC } from 'react'

import type { PageProps } from 'gatsby'

export interface SEOProps {
  pageTitle: string | string[]
  subHeading: string | string[]
  topicTitle?: string
  country?: string
  // countries?: string[]
  language?: string
  languages?: string[]
  slug?: string
  location: PageProps['location']
  template?: string
  children?: React.ReactNode
}

const SEO: FC<SEOProps> = ({
  pageTitle,
  subHeading,
  topicTitle,
  country,
  language,
  languages,
  slug,
  location,
  template,
  children,
}) => {
  const localizedPageTitle = getLocalizedValue(pageTitle)
  const localizedSubHeading = getLocalizedValue(subHeading)

  const titleElement = topicTitle
    ? `${topicTitle} - ${localizedPageTitle} - ${localizedSubHeading}`
    : typeof pageTitle === 'string'
      ? `${localizedPageTitle} - ${localizedSubHeading}`
      : localizedPageTitle

  const href = location.href

  const alternateLinks = generateAlternateLinks({
    origin: location.origin,
    country,
    language,
    languages,
    slug,
  })

  const bodyClassName =
    country && language ? `${country}-${language} ${template}` : template

  return (
    <>
      <html lang={language ?? languages?.join(',')} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
      <meta name="description" content={titleElement} />
      <meta
        httpEquiv="Content-Language"
        content={language ?? languages?.join(',')}
      />
      <title>{titleElement}</title>
      <link rel="canonical" href={href} />
      {alternateLinks}
      <body className={bodyClassName} />
      {children}
    </>
  )
}

export default SEO

const getLocalizedValue = (value: string | string[]): string => {
  if (typeof value === 'string') {
    return value
  } else {
    // Extract all distinct translations from the record.
    // console.log('SEO getLocalizedValue() value', value)

    // const uniqueTitles = Array.from(new Set(Object.values(value)))
    // return uniqueTitles.join(' / ')
    return value.join(' / ')
  }
}

const generateAlternateLinks = ({
  origin,
  country,
  language,
  languages,
  slug,
}: {
  origin: string
  country?: string
  language?: string
  languages?: string[]
  slug?: string
}): JSX.Element[] | null => {
  // If there's no languages array, no country, or if we're on a country page, return null.
  if (!languages || !country || !language) return null

  return languages
    .filter(lang => lang !== language) // Exclude current language
    .map((lang, index) => {
      let href = `${origin}/${country}/${lang}/`

      if (slug) {
        href += `${slug}/`
      }

      const hrefLangValue = country ? `${lang}-${country.toUpperCase()}` : lang

      return (
        <link
          key={index}
          rel="alternate"
          hrefLang={hrefLangValue}
          href={href}
        />
      )
    })
}
