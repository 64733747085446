import React, { FC } from 'react'

import { useMainProps } from '../context'

import { Background, Header, Transition, Footer } from '../components'

import { useLocation, useMobileDesign } from '../hooks'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const mainProps = useMainProps()
  const location = useLocation()
  const isMobile = useMobileDesign()

  return (
    <>
      <Background />
      <Header />
      <Transition className={mainProps} location={location}>
        {children}
      </Transition>
      {!isMobile && <Footer />}
    </>
  )
}

export default Layout
