import React, {
  FC,
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { navigate } from 'gatsby'
import Fuse from 'fuse.js'

import { useRoute } from '../context'

import type { Localized, Topic, Section, Agency } from '../types'

export type SearchBase = Agency & {
  sectionId: Section['id']
  sectionTitle: Section['title']
  topicTitle: Topic['topicTitle']
  topicSlug: Localized['slug']
}

type SearchResult = SearchBase & {
  matches?: readonly Fuse.FuseResultMatch[]
}

type ApiResponse = SearchBase[]

interface SearchProviderProps {
  query?: string
  children: React.ReactNode
}

interface SearchContextProps {
  fuseInstance: Fuse<SearchBase> | null
  index: SearchBase[]
  setIndex: React.Dispatch<React.SetStateAction<SearchBase[]>>
  searchBoxState: Set<string>
  searchBoxStatesClass: string
  setSearchBoxState: (state: string, shouldAdd?: boolean) => void
  resetSearchBoxState: () => void
  searchBoxQuery: string
  setSearchBoxQuery: React.Dispatch<React.SetStateAction<string>>
  searchBoxOrigin: string
  setSearchBoxOrigin: React.Dispatch<React.SetStateAction<string>>
  searchResults: SearchResult[]
  setSearchResults: React.Dispatch<React.SetStateAction<SearchResult[]>>
  fetchSearchResults: (query: string) => void
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined)

export const SearchProvider: FC<SearchProviderProps> = ({
  query = '',
  children,
}) => {
  if (!process.env.GATSBY_CURRENT_VERSION) {
    throw new Error(
      'The GATSBY_CURRENT_VERSION environment variable is not set!',
    )
  }
  const currentVersion: string = process.env.GATSBY_CURRENT_VERSION

  const [fuseInstance, setFuseInstance] = useState<Fuse<SearchBase> | null>(
    null,
  )
  const [index, setIndex] = useState<SearchBase[]>([])
  const [searchBoxState, toggleSearchState] = useState<Set<string>>(new Set())
  const [searchBoxQuery, setSearchBoxQuery] = useState<string>(query)
  const [searchBoxOrigin, setSearchBoxOrigin] = useState<string>('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])

  const { country, language } = useRoute()

  const searchBoxStatesClass = useMemo(() => {
    if (searchBoxState.size > 0) {
      return [...searchBoxState].map(state => `searchbox--${state}`).join(' ')
    }
    return ''
  }, [searchBoxState])

  const fuseOptions: Fuse.IFuseOptions<SearchBase> = useMemo(
    () => ({
      keys: ['title', 'body', 'webUrl', 'topicTitle', 'sectionTitle'],
      includeMatches: true,
      threshold: 0.2,
    }),
    [],
  )

  const fetchSearchResults = (query: string): void => {
    if (fuseInstance) {
      const results = fuseInstance.search(query)

      const combinedResults = results.map(result => ({
        ...result.item,
        matches: result.matches,
      }))

      setSearchResults(combinedResults)

      void navigate(
        `/${country}/${language}/search?query=${encodeURIComponent(query)}`,
      )
      // .then(() => {
      //   if (process.env.NODE_ENV === 'development') {
      //     // Only attach a catch block in development mode
      //     // eslint-disable-next-line promise/no-return-wrap, prefer-promise-reject-errors
      //     return Promise.reject('Navigation failed in development mode.')
      //   }
      // })
      // .catch(error => {
      //   if (process.env.NODE_ENV === 'development') {
      //     // eslint-disable-next-line no-console
      //     console.error('Failed to navigate:', error)
      //   }
      // })
    }
  }

  const setSearchBoxState = (state: string, shouldAdd = true): void => {
    toggleSearchState(prev => {
      const newSet = new Set(prev)
      if (shouldAdd) {
        newSet.add(state)
      } else {
        newSet.delete(state)
      }
      return newSet
    })
  }

  const resetSearchBoxState = (): void => {
    toggleSearchState(new Set())
  }

  useEffect(() => {
    if (!country || !language) return

    const cachedVersion: string | null =
      localStorage.getItem('searchIndexVersion')
    const cachedIndex: string | null = localStorage.getItem(
      `${country}-${language}-searchIndex`,
    )

    if (cachedIndex && cachedVersion === currentVersion) {
      try {
        const parsedIndex = JSON.parse(cachedIndex) as SearchBase[]
        setIndex(parsedIndex)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error parsing the cached index:', error)
      }
    } else {
      // Fetch the index if not in localStorage or if version mismatch...

      // If the index is empty, fetch it.
      fetch(`/index-${country}-${language}.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok: ${response.statusText}`,
            )
          }
          return response.json() as Promise<ApiResponse>
        })
        .then((data: SearchBase[]) => {
          // Check if data is non-empty or meets other desired conditions
          if (data.length > 0) {
            setIndex(data)
            localStorage.setItem(
              `${country}-${language}-searchIndex`,
              JSON.stringify(data),
            )
            localStorage.setItem('searchIndexVersion', currentVersion)
          } else {
            // Handle empty data, maybe set an error state or log for further debugging
            // eslint-disable-next-line no-console
            console.error('Fetched index data is empty.')
          }
        })
        .catch(error => {
          throw new Error(`Error fetching index: ${error}`)
        })
    }
  }, [country, language, currentVersion]) // Adding index, setIndex, and query to deps array will cause infinite loop

  useEffect(() => {
    if (index.length > 0) {
      const fuseInstance = new Fuse(index, fuseOptions)
      setFuseInstance(fuseInstance)
    }
  }, [fuseOptions, index])

  return (
    <SearchContext.Provider
      value={{
        fuseInstance,
        index,
        setIndex,
        searchBoxState,
        searchBoxStatesClass,
        setSearchBoxState,
        resetSearchBoxState,
        searchBoxQuery,
        setSearchBoxQuery,
        searchBoxOrigin,
        setSearchBoxOrigin,
        searchResults,
        setSearchResults,
        fetchSearchResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = (): SearchContextProps => {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error('useSearch must be used within an SearchProvider')
  }
  return context
}

// export const withSearch = <P extends object>(
//   WrappedComponent: React.ComponentType<P>,
// ): React.FunctionComponent<P> => {
//   // eslint-disable-next-line react/display-name
//   return props => (
//     <SearchContext.Consumer>
//       {(context): React.JSX.Element => {
//         if (!context) {
//           throw new Error('withSearch must be used within a SearchProvider')
//         }
//         return <WrappedComponent {...props} {...context} />
//       }}
//     </SearchContext.Consumer>
//   )
// }

// Add SearchContextProps to the named export
// export type { SearchContextProps } from '../context/SearchContext'

// Add withSearch to the named exports
// export { withSearch } from '../context/SearchContext'

// Import hook and type
// import { withSearch } from '../components'
// import type { SearchContextProps } from '../types/app-types'

// const HeadComponent: FC<
//   HeadFC & SearchResultsProps & { context: SearchContextProps }
// > = ({ pageContext, data, context }) => {
//   console.log(pageContext)
//   console.log(data)
//   console.log(context)

//   const { country, language } = pageContext
//   const { pageTitle, subHeading } = data.searchData! // eslint-disable-line @typescript-eslint/no-non-null-assertion

//   const location = useLocation()

//   const seoProps = {
//     pageTitle,
//     subHeading,
//     country,
//     language,
//     template: 'search-results',
//     location,
//   }

//   return <SEO {...seoProps} />
// }

// export const Head = withSearch(HeadComponent)
