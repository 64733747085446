import React from 'react'

import { RouteProvider, SearchProvider, MenusProvider } from './src/context'

import Layout from './src/components/Layout'

import { extractDataFromPath, changeLanguage } from './src/utils'

import './src/styles/global.scss'

import type { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby'
import type { PageTypeIndex } from './src/types'

interface WrapperProps {
  location: Pick<WrapRootElementBrowserArgs, 'pathname'>
}

interface WrapPageElementArgs {
  element: React.ReactElement
  props: WrapperProps
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElementArgs) => {
  const [country, language, slug] = extractDataFromPath(props.location.pathname)

  const elementType = element.type as React.ElementType

  const displayName =
    typeof elementType !== 'string' ? elementType.displayName : undefined

  const currentUrl = location.href

  const newURL = new URL(currentUrl)
  const query = newURL.searchParams.get('query') ?? undefined

  return (
    <RouteProvider
      country={country}
      language={language}
      slug={slug}
      component={displayName as PageTypeIndex}
    >
      <SearchProvider query={query}>
        <MenusProvider>
          <Layout {...props}>{element}</Layout>
        </MenusProvider>
      </SearchProvider>
    </RouteProvider>
  )
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location,
  prevLocation,
}): void => {
  const [newCountry, newLanguage] = extractDataFromPath(location.pathname)
  const [oldCountry, oldLanguage] = prevLocation
    ? extractDataFromPath(prevLocation.pathname)
    : [null, null]

  if (!newCountry || !newLanguage) {
    return
  }

  if (newCountry !== oldCountry || newLanguage !== oldLanguage) {
    changeLanguage(newLanguage, newCountry).catch(error => {
      // eslint-disable-next-line no-console
      console.error('Failed to change the language:', error)
    })
  }
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
}): boolean => {
  const { state } = location as { state?: { hash?: string } }

  let options: ScrollToOptions = { top: 0, behavior: 'smooth' }

  if (state?.hash) {
    const targetSection = document.getElementById(state.hash)
    if (targetSection) {
      const positionOffset = targetSection.getBoundingClientRect().top - 100
      options = { ...options, top: positionOffset }
    }
  }

  window.scrollTo(options)

  return false
}
