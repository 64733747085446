export const countryMenuRegionOrder = ['america', 'latam', 'europe', 'apac']

export const topicMenuOrder = [
  'substance-abuse-addiction',
  'violence-sexual-assault',
  'depression-self-harm',
  'lgbtq-support',
  'religious-cultural-sensitivities',
  'bullying-online-safety',
  'other-mental-health-support',
]
