import React, { FC, useRef, useCallback } from 'react'

import { useSearch, useMenus } from '../context'

import { SearchClose } from '../components'

import {
  useTranslation,
  useMobileDesign,
  useLocation,
  useOutsideClick,
} from '../hooks'

import { Search } from '../icons'

const SearchBox: FC = () => {
  const searchBoxRef = useRef<HTMLFormElement>(null)
  const mouseEnterTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const searchBoxQueryRef = useRef<string>('')

  const { t } = useTranslation()

  const {
    searchBoxState,
    setSearchBoxQuery,
    setSearchBoxState,
    setSearchBoxOrigin,
    fetchSearchResults,
  } = useSearch()

  const { setIsMobileMenuVisible } = useMenus()

  const isMobile = useMobileDesign()

  const location = useLocation()

  const handleSubmit = useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault()

      if (searchBoxState.has('focus') && searchBoxQueryRef.current.length > 0) {
        setSearchBoxState('focus', false)
        setSearchBoxQuery(searchBoxQueryRef.current)
        setSearchBoxOrigin(location.pathname)
        setIsMobileMenuVisible(false)
        fetchSearchResults(searchBoxQueryRef.current)
      } else if (!searchBoxState.has('focus')) {
        setSearchBoxState('focus')
      }
    },
    [location.pathname, searchBoxState], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleDelayedMouseEnter = useCallback((): void => {
    setSearchBoxState('focus')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMouseEnter = useCallback((): void => {
    searchBoxRef.current?.querySelector('input')?.focus()

    mouseEnterTimeoutRef.current = setTimeout(handleDelayedMouseEnter, 250)
  }, [handleDelayedMouseEnter])

  const handleMouseLeave = useCallback((): void => {
    searchBoxRef.current?.querySelector('input')?.blur()

    setSearchBoxState('focus', false)

    if (mouseEnterTimeoutRef.current) {
      clearTimeout(mouseEnterTimeoutRef.current)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const target = e.currentTarget
      searchBoxQueryRef.current = target.value
    },
    [],
  )

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>): void => {
      const target = e.currentTarget
      target.select()
      setSearchBoxState('focus')
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleClick = useCallback((): void => {
    setSearchBoxState('focus')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useOutsideClick(searchBoxRef, () => {
    setSearchBoxState('focus', false)
  })

  return (
    <div className="searchbox">
      <form
        ref={searchBoxRef}
        onSubmit={handleSubmit}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button type="submit" title={`${t('searchLabel')}...`}>
          <Search />
        </button>
        <input
          type="text"
          defaultValue={searchBoxQueryRef.current}
          placeholder={`${t('searchLabel')}...`}
          onChange={handleChange}
          onFocus={handleFocus}
          onClick={handleClick}
          onTouchEnd={handleClick}
        />
        {isMobile && searchBoxState.has('focus') && <SearchClose />}
      </form>
    </div>
  )
}

export default SearchBox
