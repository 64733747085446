import React, { FC, useCallback, useEffect, useRef, memo } from 'react'
import { Link } from 'gatsby'

import { useRoute, useSearch } from '../context'

import { useTranslation } from '../hooks'

import { Search } from '../icons'

const SearchResults: FC = () => {
  const searchBoxQueryRef = useRef<string>('')

  const { t } = useTranslation()

  const { country, language } = useRoute()

  const {
    index,
    fuseInstance,
    searchBoxQuery,
    setSearchBoxQuery,
    setSearchBoxOrigin,
    searchResults,
    setSearchResults,
    fetchSearchResults,
  } = useSearch()

  useEffect(() => {
    if (searchBoxQuery.length > 0) {
      searchBoxQueryRef.current = searchBoxQuery
    }
  }, [searchBoxQuery])

  useEffect(() => {
    if (searchResults.length === 0 && searchBoxQuery.length > 0) {
      fetchSearchResults(searchBoxQuery)
    }
  }, [index, fuseInstance, searchBoxQuery, searchResults.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback((e: React.FormEvent): void => {
    e.preventDefault()
    if (searchBoxQueryRef.current.length > 0) {
      fetchSearchResults(searchBoxQueryRef.current)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const target = e.currentTarget
      searchBoxQueryRef.current = target.value

      if (searchBoxQueryRef.current.length === 0) {
        setSearchBoxQuery('')
        setSearchResults([])
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleFocus = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const target = e.currentTarget
      target.select()
    },
    [],
  )

  const handleClick = useCallback((): void => {
    setSearchBoxOrigin('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="searchbox">
        <form onSubmit={handleSubmit}>
          <Search />
          <input
            type="text"
            defaultValue={searchBoxQuery}
            placeholder={`${t('searchLabel')}...`}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </form>
      </div>
      <div>
        {/* Render the search results */}
        {searchResults.map((result, idx) => {
          return (
            <div key={idx} className="agency">
              <h3>
                <Link
                  to={`/${country}/${language}/${result.topicSlug}`}
                  state={{ hash: result.agencyId }}
                  dangerouslySetInnerHTML={{ __html: result.title }}
                  onClick={handleClick}
                />
              </h3>
              <h4>
                <Link
                  to={`/${country}/${language}/${result.topicSlug}`}
                  state={{ hash: result.sectionId }}
                  onClick={handleClick}
                >
                  {result.sectionTitle
                    ? `${result.topicTitle} / ${result.sectionTitle}`
                    : result.topicTitle}
                </Link>
              </h4>
              <p dangerouslySetInnerHTML={{ __html: result.body }} />
              <div>
                {result.webUrl && (
                  <p>
                    <a href={result.webUrl} target="_blank" rel="noreferrer">
                      {t('learnMore')}
                    </a>
                  </p>
                )}
                {result.phoneNumber && (
                  <p>
                    <a href={`tel:${result.phoneNumber}`}>
                      {t('call')} {result.phoneNumber}
                    </a>
                  </p>
                )}
                {result.textNumber && (
                  <p>
                    <a href={`sms:${result.textNumber}`}>
                      {t('text')} {result.textNumber}
                    </a>
                  </p>
                )}
                {result.appUrl && (
                  <p>
                    <a href={result.appUrl}>{t('downloadApp')}</a>
                  </p>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default memo(SearchResults)
