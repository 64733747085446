import React from 'react'

export function SplitAtStopWords(string: string, stopWords: string[]): string {
  const regex = new RegExp(`(${stopWords.join('|')})`, 'g')
  return string.split(regex).reduce((acc, part, index, array) => {
    // Directly append the part itself.
    acc += part
    // If the part is a stop word and it's not the last part in the array, append a <br> tag.
    if (stopWords.includes(part) && index !== array.length - 1) {
      acc += '<br>'
    }
    return acc
  }, '')
}

export function SplitAtStopWords2({
  string,
  stopWords,
}: {
  string: string
  stopWords: string[]
}): JSX.Element {
  const regex = new RegExp(`(${stopWords.join('|')})`, 'g')
  const parts = string.split(regex).filter(Boolean)

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {stopWords.includes(part) ? <br /> : null}
        </React.Fragment>
      ))}
    </>
  )
}
