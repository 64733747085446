import React, { FC, useRef, useState, useCallback } from 'react'
import { Link } from 'gatsby'

import { useMenus } from '../context'

import { useTranslation, useOutsideClick } from '../hooks'

import { MenuTopic, MenuTopicActive } from '../icons'

const TopicMenu: FC<{ hamburger?: boolean }> = ({ hamburger }) => {
  const { t } = useTranslation()

  const {
    topics,
    isTopicMenuVisible,
    setIsTopicMenuVisible,
    setIsMobileMenuVisible,
  } = useMenus()

  const menuRef = useRef<HTMLElement>(null)
  const menuItemsRef = useRef<HTMLUListElement>(null)

  const [isFocus, setIsFocus] = useState<boolean>(false)

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> =
    useCallback((): void => {
      setIsFocus(true)
    }, [])

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> =
    useCallback((): void => {
      setIsTopicMenuVisible(false)
      setIsFocus(false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = useCallback((): void => {
    setIsMobileMenuVisible(false)
    setIsTopicMenuVisible(prevState => !prevState)

    menuItemsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMouseClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((): void => {
      handleClick()
    }, [handleClick])

  const handleTouch: React.TouchEventHandler<HTMLButtonElement> = useCallback(
    (e): void => {
      e.preventDefault()
      handleClick()
    },
    [handleClick],
  )

  const handleKey: React.KeyboardEventHandler<HTMLButtonElement> = useCallback(
    (e): void => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleClick()
      }
    },
    [handleClick],
  )

  const handleLinkClick = useCallback((): void => {
    setIsTopicMenuVisible(false)
    setIsMobileMenuVisible(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useOutsideClick(menuRef, () => {
    setIsTopicMenuVisible(false)
    setIsFocus(false)
  })

  return (
    <nav
      ref={menuRef}
      className={`menu ${
        hamburger ? 'menu--hamburger--topic' : 'menu--topic'
      } ${isFocus ? 'menu--topic--focus' : ''} ${
        isTopicMenuVisible ? 'menu--topic--active' : ''
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`menu--header ${
          hamburger
            ? 'menu--hamburger--header menu--hamburger--topic--header'
            : 'menu--topic--header'
        }`}
      >
        {hamburger ? (
          <span className="menu--header--label">{t('topicMenuHead')}</span>
        ) : (
          <button
            className="menu--header--button"
            type="button"
            title={t('topicMenuHead')}
            onClick={handleMouseClick}
            onTouchEnd={handleTouch}
            onKeyUp={handleKey}
          >
            <span className="menu--header--label">{t('topicMenuHead')}</span>
            <div className="menu--header--icon">
              {isTopicMenuVisible ? <MenuTopicActive /> : <MenuTopic />}
            </div>
          </button>
        )}
      </div>
      <ul ref={menuItemsRef}>
        {topics.map(topic => (
          <li key={topic.slug}>
            <Link
              to={topic.slug}
              activeClassName="link--active"
              title={topic.topicTitle}
              dangerouslySetInnerHTML={{ __html: topic.topicTitle }}
              onClick={handleLinkClick}
            />
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default TopicMenu
