import React, { FC } from 'react'
import { createGlobalStyle } from 'styled-components'

import { useRoute, PageType } from '../context'

const Backgrounds: FC = () => {
  const { topic, pageType } = useRoute()

  const page =
    pageType === PageType.TopicTemplate
      ? topic
      : pageType === PageType.LandingTemplate
        ? 'landing'
        : null

  if (!page) return null

  const background = `/backgrounds/${page}.jpg`
  const backgroundMobile = `/backgrounds/${page}-m.jpg`

  return (
    <GlobalStyle
      regularBackground={background}
      backgroundMobile={backgroundMobile}
    />
  )
}

export default Backgrounds

const GlobalStyle = createGlobalStyle<{
  regularBackground: string
  backgroundMobile: string
}>`
  #___gatsby {
    background-image: url(${(props: { regularBackground: string }): string =>
      props.regularBackground});
  }

  @media (pointer: coarse) {
    #___gatsby {
      background-image: url(${(props: { backgroundMobile: string }): string =>
        props.backgroundMobile});
    }
  }
`
